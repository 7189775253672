.success{
    color: rgb(50, 168, 82);
}

.warning{
    color: rgb(230, 215, 16);
}

.danger{
    color: rgb(230, 16, 16);
}