.navbar{
    width: inherit;
}

.brand_wrapper{
    text-align: center;
    padding-top: 2rem;
    flex: 20%;
    order: 1;
    background: rgb(242, 255, 255);

}

.brand_name{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1rem;
    color: rgb(232,99,91);
    text-decoration: none;
}

.menu_wrapper{
  padding: 0;
  flex: 80%;
  display: flex;
  justify-content: flex-end;
  order: 2;
  flex-wrap: wrap;
  background:  rgb(239, 90, 114);
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 0 0 0 0.5rem;

}

.mobile_navbar{
    display: none;
}


.align_items_stretch{
    align-items: stretch;
}

.d_flex{
    display: flex;
}

.nav_right{
    list-style-type: none;
    display: inline-flex;
    margin: 0;
    color: rgb(255,255,255);
}

.nav_item{
    padding: 1rem;
}

.nav_profile_dropdown_toggle{
    display: flex;
    cursor: pointer;
    flex-direction: row;
}


.profile_img_container{
    border-radius: 5em;
}

.profile_text_container{
    margin-left: 1.25rem;
    font-size: 1rem;
    margin-top: 0.1rem;
}

.profile_text_container i{
    margin-left: 0.55rem;
    font-size: 0.9rem !important;
}

.img_profile{
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.img_profile:hover{
    width: 38px;
    height: 38px;

}

.nav_profile_dropdown_name:hover{
    font-size: 1.4rem;
    font-weight: 700;
}

.menu_wrapper .nav_right .nav_item i{
    font-size: 1.2rem;
}

.menu_wrapper .nav_right .nav_item i:hover{
    background: white;
    color: #e8635b;
    padding: 1rem;

}

.btn_icon{
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}

.nav_profile_dropdown_menu{
    position: absolute;
    top: 2.5rem;
    color: #000;
    background-color: rgb(255,255,255);
    padding: 0.5rem;
    box-shadow: 0 4px 4px 0 #00000040;
    border-radius: 0.5rem;
    z-index: 1000;
}

.nav_profile_dropdown_menu ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1rem;

}

.nav_profile_dropdown_menu ul li{
    border-bottom: 1px solid rgb(242, 245, 245);
}

.mobile_menu_nav_item a{
    color:#e8635b;
}

/**
For Mobile Devices
*/
@media only screen and (max-width: 50em){

    .menu_wrapper{
        display: none;
    }

    .mobile_navbar{
        position: fixed;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        background:  rgb(239, 90, 114);
        box-shadow: 0 4px 4px 0 #00000040;
        border-radius: 0 0 0 0.5rem;
        width: -webkit-fill-available;
        height: 4rem;
        z-index: 1000;
    }

    .notification_icon{
        position: fixed;
        font-size: 1.2rem;
        padding: 1rem;
        color: rgb(255,255,255);
    }

    .toggle_menu_icon{
        position: fixed;
        font-size: 1.3rem;
        padding: 1rem;
    }

    .toggle_menu_icon i{
        background: white;
        color:  rgb(239, 90, 114);
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        padding: 0.5rem;
        font-weight: 500;
    }

    .mobile_menu{
        position: relative;
        top: 3rem;
        right: 2rem;
        color: #000;
        background-color: rgb(255,255,255);
        padding: 0.5rem;
        box-shadow: 0 4px 4px 0 #00000040;
        border-radius: 0.5rem;
    }

    .mobile_menu_ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .mobile_menu_ul li{
        border-bottom: 1px solid rgb(242, 245, 245);
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.85rem;
        padding-right: 0.85rem;
        cursor: pointer;
    }

    .btn_icon{
        color:  rgb(239, 90, 114);
    }
  
  }

  
