.search_skills_container{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    flex-direction: column;
}

.search_bar{
    border-radius: 3em;
    background-color: rgb(10,10,10);
    border: 1px solid rgb(10,10,10);
    height: 47px;
    width: 50%;
    color: rgb(236, 229, 229);
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    align-self: center;
}

.feedback_text{
    align-self: center;
    font-style: italic;
}

.search_options{
    background-color: rgb(255,255,255);
    align-self: center;
    margin-top: 2rem;
    width: 50%;
    box-shadow: 0 4px 4px 0 #00000040;
    position: relative;
}



.option_item{
    border-bottom: 1px solid rgba(239, 90, 114, 0.22);
    padding: 1rem;
    cursor: pointer;
}

.option_item:hover{
    background-color: rgba(239, 90, 114, 0.22);;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .search_bar{
        width: 80%;
    }

    .search_options{
        margin-top: 0.25rem;
        width: 90%;
    }

}
