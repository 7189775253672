.card{
    width: 100%;
    min-width: 100%;
}

.card {
    border: 0;
    background: #fff;
    flex-direction: column;
    border-radius: 1rem;
    word-wrap: break-word;
    display: flex;
}

.card_body{
    padding: 0.5rem;
    flex: 1 1 auto;
    min-height: 1px;
}