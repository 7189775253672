.upload_container{
    margin-top: 2rem;
    text-align: center;
    width: max-content;
    align-self: center;
}

.samples_container{
    margin-top: 2rem;
}

.samples_container{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.card_container{
    border: 1px solid rgb(221, 222, 224);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
}

.card_container:hover{
    transform: scale(1.1);
}


.placeholder{
    width: 14rem;
    height: auto;
}

.btn_remove{
    width: max-content;
    align-self: flex-end;
    cursor: pointer;
}

.btn_upload{
    background: rgb(255,255,255);
    border: 1px solid rgb(239,90,114);
    border-radius: 1.2rem;
    color: rgb(239,90,114);
    padding: .05em;
    font-size: 1.2rem;
    cursor: pointer;
    width: max-content;
    font-weight: normal;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .samples_container{
        flex-direction: column;
    }
    
}