.form_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .form_container{
        display: flex;
        flex-direction: column;
    }
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .form_container{
        display: flex;
        flex-direction: column;
    }

}  

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .form_container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

} 


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .form_container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

} 
}