.password_text_input{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.password_text_input input{
    height: 2rem;
    padding: 0.625em 1em;
    width: -moz-available;
    border: 1px solid rgba(128, 128, 128,0.25);
    color: rgb(128, 128, 128);
    border-radius: 0.25rem;

}

.password_text_input label{
    font-size: 1rem;
}