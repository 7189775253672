.wrapper{
    padding: 3.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 0.5rem;
}


.flex_container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.tabs{
    display: inline-flex;
    border-bottom: 1px solid rgb(233, 103, 95);
}

.tab_heading{
    padding: 1rem;
    cursor: pointer;
    color: rgb(233, 103, 95);
}

.tab_heading h5{
    margin: 0;
    font-size: 1rem;
}

.active_tab{
    background-color: rgb(233, 103, 95);
    color: rgb(255, 255, 255);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem ;
}

.tab_content{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}

.project_card{
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    background-color: rgb(255,255,255);
}

.project_info{
    padding: auto;
}

.project_category{
    font-weight: 700;
    margin: 0.5rem 0 0.5rem 0;
}

.project_location{
    margin: 0.5rem 0 0.5rem 0;
}

.project_booking_date{
    margin: 0.5rem 0 0.5rem 0;
}

.project_booking_time{
    margin: 0.5rem 0 0.5rem 0;
}

.project_upcoming{
    background-color: rgb(233, 255, 95);
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 3em;
}

.project_actions{
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.btn_go_to_project{
    background: rgb(232,99,91);
    border: 1px solid rgb(232,99,91);
    border-radius: 3em;
    color: rgb(255,255,255);
    padding: .5em;
    font-size: 1rem;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    width: max-content;
}