.alert_icon{
    font-size: 1.5rem;
    padding-right: 1rem;
}

.btn_close{
    cursor: pointer;
    background: none;
    border: 0;
    
    padding-left: 1rem;
}

.alert_info{
    background: rgb(24, 192, 201);
    color: rgb(255,255,255);
    padding: 0.5rem;
}

.alert_success{
    background: rgb(24, 201, 77);
    color: rgb(255,255,255);
    padding: 0.5rem;
}

.alert_warning{
    background: rgb(240, 221, 17);
    color: rgb(255,255,255);
    padding: 0.5rem;
}

.alert_danger{
    background: rgb(240, 54, 17);
    color: rgb(255,255,255);
    padding: 0.5rem;
}