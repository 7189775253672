.skill_card_icon{
    font-size: 2rem;
    align-self: center;
}

.selected_handyman_skills{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.skill_card{
    border-radius: 20px;
    border: 1px solid rgb(221, 222, 224);
    background-color: rgb(255,255,255);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    flex-wrap: wrap;
    overflow: hidden;
    clear: both;
    width: 10rem;
}

.skill_card:hover{
    transform: scale(1.1);
}

.skill_card_header{
    font-size: 1.3rem;
    margin: 0;
    align-self: center;
}

.btn_select_skill{
    width: max-content;
    background: rgb(255,255,255);
    font-size: 1rem;
    border: 1px solid rgb(239, 90, 114);
    border-radius: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    cursor: pointer;
    align-self: center;
}

.skill_remove_icon{
    align-self: flex-end;
    color : rgb(239, 90, 114);
    cursor: pointer;
    font-size: 1.3rem;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .selected_handyman_skills{
        flex-direction: column;
    }

    .skill_card{
        width: 20rem;
    }

}