.sidebar{
    background: rgb(252, 255, 255);
    /*min-height: calc(100vh - 70px);*/
    box-shadow: 0 4px 4px 0 #00000040;
    max-width: 80px;
    position: relative;
    top: 2rem;
    height: max-content;
}

.nav_ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 5rem;
}

.sidemenu_item{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #0000000f;
}



.nav_ul a span{
    color: rgb(239, 90, 114);
    padding-left: 1rem;
    font-weight: 700;
}

.nav_ul a i{
    color: rgb(239, 90, 114);
    font-size: 1.5rem;
    
}

.nav_link{
    text-decoration: none;
    width: 3.333vw;
    height: 3.333vw;
    font-size: 1rem;
    display: flex;
    justify-content: center;
}

.active{
    background: rgb(239, 90, 114);
}

.icon_active{
    color: rgb(255, 255, 255) !important;
}


/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){

    .sidebar{
        display: none;
    }
  
  }

  
  /**
* For Tablets
*/
@media (min-width: 60em) and  (max-width: 75em){

  
  
}