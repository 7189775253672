/* Base styles for all buttons */
.button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 1.2rem;
    cursor: pointer;
    font-size: 0.875rem;
    width: max-content;
    align-self: center;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  /* Default button style */
  .button_default {
    background-color: rgb(239,90,114); 
    color: rgb(255,255,255);
  }
  
  /* Default button hover effect */
  .button_default:hover {
    background-color: rgb(239,90,114);
  }
  
  /* Outline button style */
  .button_default_outline {
    background-color: transparent;
    color: rgb(239,90,114);; /* Green */
    border: 1px solid rgb(239,90,114);
  }
  
  /* Outline button hover effect */
  .button_default_outline:hover {
    background-color: rgb(239,90,114);;
    color: rgb(255,255,255);
  }
  
  /* Icon styles */
  .button_default_outline {
    margin-right: 5px;
  }


   /* Info button style */
   .button_info {
    background-color: rgb(36, 33, 33); 
    color: rgb(255,255,255);
  }


   /* Info button hover effect */
   .button_info:hover {
    background-color: rgb(36, 33, 33);
  } 

   
  /* Outline button style */
  .button_info_outline {
    background-color: transparent;
    color: rgb(36,33,33); /* Green */
    border: 1px solid rgb(36,33,33);
  }

   /* Outline button hover effect */
   .button_info_outline:hover {
    background-color: rgb(36,33,33);
    color: rgb(255,255,255);
  }