.form{
    width: 100%;
}

.row_1{
    display: grid;
    grid-template-columns: 1fr;
}

.col_center{
    justify-content: center;
    align-content: center;
}

.row_2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.col{
    display: inherit;
    margin-top: 2rem;
}

.input{
    height: 2rem;
    margin-top: 0.5rem;
    padding: 0.625em 1em;
    width: -moz-available;
    border: 1px solid rgb(128, 128, 128,0.25);
    color: rgb(239,90,114);
    border-radius: 0.25rem;
}

label {
    grid-column: 1 / 2;
}

.text_align_center{
    text-align: center;
}

.btn_save{
    background: rgb(255,255,255);
    border: 1px solid rgb(239,90,114);
    border-radius: 1.2rem;
    color: rgb(239,90,114);
    padding: .5em;
    font-size: 1.2rem;
    cursor: pointer;
    width: 15rem;
    font-weight: normal;
}

