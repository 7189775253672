
.page_header{
    margin: 0;
    align-self: center;
    text-align: center;
}

@media only screen and (max-width: 50em){
    .page_header{
        text-align: center;
        align-self: center;
    }
}