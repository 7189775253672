.container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background: rgb(92, 174, 193);
    height: 100vh;
}

.row{
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.card{
    border-radius: 0.5rem;
    width: 100%;
    max-width: 20em;
    padding: 2rem;
    background: rgb(255,255,255);
}

.card_header{
    display: flex;
    flex-direction: column;
}

.card_icon{
    align-self: center;
    background: rgb(239,90,114);
    border-radius: 3em;
}

.card_icon i{
    font-size: 3.75rem;
    color:  rgb(232,255,255);
    padding: 0.9rem;
}

.card_header h2{
    text-align: center;
}

.card_header p{
    font-size: 1rem;
    color: rgb(128, 128, 128);
}

.card_title{
    font-size: 2rem;
    font-weight: 700;
    line-height: 1rem;
    color: rgb(239,90,114);
}

.card_body{
    padding: 1rem;
}

.card input{
    margin-top: 0.5rem;
    padding: 0.625em 1em;
    width: -moz-available;
    width: -webkit-fill-available;;
    height: auto;
    border: 1px solid rgb(128, 128, 128,0.25);
    color: rgb(128, 128, 128);
    border-radius: 0.25rem;
}

.label{
    padding-bottom: 1rem;
    font-size: 1rem;
    color: rgb(128, 128, 128);
}

.form_control{
    width: 100%;
}

.form_group{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.link_forgot_password{
    color: rgb(239,90,114);
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
}

.btn_login{
    background: rgb(239,90,114);
    border: 1px solid rgb(239,90,114);
    border-radius: 1.2rem;
    color: rgb(255,255,255);
    padding: .5em;
    font-size: 1.3rem;
    cursor: pointer;
    min-width: 100%;
    min-width: stretch; 
    width: -moz-available;
    width: -webkit-fill-available;
    font-weight: normal;
}

.btn_login:hover{
    background: rgb(255,255,255);
    border: 1px solid rgb(239,90,114);
    color: rgb(232,99,91);
} 

.input_invalid{
    border: 1px solid rgb(230, 16, 16);
}

.card input:hover {
    background-color: rgb(255,255,255);
    cursor: pointer;
  }

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){

    .row{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .card{
        width: 100%;
        max-width: 16em;
        padding: 2rem;
    }

    .card input{
        margin-top: 0.5rem;
        padding: 0.625em 0.5em;
        width: 100%;
        height: auto;
    }

}

/**
* For Tablets
*/
@media (min-width: 60em) and  (max-width: 75em){

    .card{
        width: 100%;
        max-width: 20em;
        padding: 2rem;
    }

}