

.content{
    display: grid;
    grid-template-columns: auto 1fr;    
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){

    .content{
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 5rem;
      
    }
  
  
  }