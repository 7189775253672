.container {
    display: flex;
    align-items: center;
  }
  
  .percentage {
    margin-right: 1rem;
  }
  
  .bar {
    width: 100%;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: lightgray;
  }
  
  .progress {
    height: 100%;
    border-radius: inherit;
    background-color: rgb(239, 90, 114);;
  }
  