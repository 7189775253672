.wrapper{
    display: grid;
    grid-row: auto;
    padding: 3.5rem;
    grid-template-columns: 1fr;
}


.flex_container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.flex_row{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.certification_container{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap:1rem;
}


.certification_card{
    padding: 0.5rem;
    background-color: rgba(255, 255, 255);
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    justify-content: space-between;
    padding: 2rem;

}


.certification_title{
    font-size: 1rem;
    margin: 0;
}

.certification_details{
    display: inline-block;
}

.certification_actions{
    display: flex-end;
    flex-direction: row;
    gap: 1rem;
}


.btn_add_new{
    background: rgb(232,99,91);
    border: 1px solid rgb(232,99,91);
    border-radius: 3em;
    color: rgb(255,255,255);
    padding: .5em;
    font-size: 1rem;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    width: max-content;
    margin-left: 3rem;
    height: max-content;
}

.btn_add_new:hover{
    background: rgb(255,255,255);
    color: rgb(232,99,91);

}

.btn_action{
    background: rgb(232,99,91);
    border: 1px solid rgb(232,99,91);
    border-radius: 0.5rem;
    color: rgb(255,255,255);
    padding: .5em;
    font-size: 1rem;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    width: max-content;
    height: max-content;

}

.btn_action:hover{
    background: rgb(255,255,255);
    color: rgb(232,99,91);

}