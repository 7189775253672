.flex_container{
    display: flex;   
    padding: 2.5rem;
    flex-direction: column;
    flex-wrap: wrap;
    
}

.notification_card{
    padding: 0.5rem;
    background-color: rgba(232, 99, 91, 0.11);
    border-radius: 0.5rem;
    margin-top: 1.5rem;
}

.notification_card p{
    margin: 0;
    padding: 0.2rem;
}