.main{
    display: flex;
    grid-row-gap: 1rem;
    padding: 2rem;
    flex-direction: column;
    justify-items: center;
} 


/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .main{
        display: grid;
        grid-row: auto;
        grid-template-columns: 1fr;
        justify-items: center;
       
    } 
    
}