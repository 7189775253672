
.profile_image_container{
    grid-column-start: 1;
    border-radius: 1.5rem;
    padding: 2.5rem;
    position: relative;
}

.profile_img{
    width: 150px;
    height: 150px;
    max-width: 10em;
    border-radius: 6rem;
    align-self: center;
}

.profile_image_container .btn_pick_image{
    position: absolute;
    bottom: 3.5rem;
    left: 9.5rem;
    background:  rgb(232,99,91);
    border: 1px solid rgb(232,99,91);
    border-radius: 3rem;
    font-size: 1rem;
    padding: 0.5rem;
    color: rgb(255,255,255);
    cursor: pointer;
}



dialog[open] {
    opacity: 1;
    transform: scaleY(1);
    
}

dialog[open]::backdrop {
    background-color: rgb(0 0 0 / 25%);
}

.modal{
    border: 0;
    width: 30rem;
}

dialog::backdrop {
    background-color: rgb(0 0 0 / 0%);
    transition:
      display 0.7s allow-discrete,
      overlay 0.7s allow-discrete,
      background-color 0.7s;
    /* Equivalent to
    transition: all 0.7s allow-discrete; */
}

.upload_modal_container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.upload_modal_button_container{
    display: inherit;
    flex-direction: inherit;
    gap: 0.5rem;   
}

.btn_close_dialog{
    float: right;
    background:  rgb(232,99,91);
    border: 1px solid rgb(232,99,91);
    border-radius: 3rem;
    padding: 0.5rem;
    cursor: pointer;
}


.preview_image{
    width: 150px;
    height: 150px;
    max-width: 10rem;
    border-radius: 6rem;
    align-self: center;
    cursor: pointer;
}

.btn_choose_image{
    float: right;
    background:  rgb(232,99,91);
    border: 1px solid rgb(232,99,91);
    border-radius: 0.25rem;
    padding: 0.5rem;
    cursor: pointer;
}

.btn_upload_image{
    float: right;
    background:  rgb(255,255,255);
    color:  rgb(232,99,91);;
    border: 1px solid rgb(232,99,91);
    border-radius: 0.25rem;
    padding: 0.5rem;
    cursor: pointer;
}

.btn_close_dialog i{
    font-size: 1rem;
}

.upload_modal_error{
    color: red;
} 



@media only screen and (max-width: 50em){

    .profile_image_container .btn_pick_image{
        position: absolute;
        bottom: 3.5rem;
        left: 9.5rem;
        background:  rgb(232,99,91);
        border: 1px solid rgb(232,99,91);
        border-radius: 3rem;
        font-size: 1rem;
        padding: 0.5rem;
        color: rgb(255,255,255);
        cursor: pointer;
    }

}