.wrapper{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.btn_delete_account{
    background: rgb(255,255,255);
    border: 1px solid rgb(239,90,114);
    border-radius: 1.2rem;
    color: rgb(239,90,114);
    padding: .5em;
    font-size: 1.2rem;
    cursor: pointer;
    width: 10rem;
    font-weight: normal;
}

.btn_delete_account:hover{
    color: rgb(255,255,255);
    background: rgb(239,90,114);
}