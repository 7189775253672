.main_wrapper{
    display: grid;
    grid-template-columns: 15rem 2fr 1fr;
    grid-template-rows: auto;
    column-gap: 1.5rem;
    row-gap: 1em;
    padding: 3.5rem;
}

.profile_container{
    grid-column-start: 1;
    border-radius: 1.5rem;
}


.profile_img{
    width: 100%;
    height: auto;
    max-width: 10em;
    border-radius: 1rem;
    align-self: center;
    margin-top: 1rem;
}

.profile_options{
    display: flex;
    justify-content: center;
}

.profile_options a{
    text-decoration: none;
}

.btn_edit_profile{
    background: rgb(255,255,255);
    border: 1px solid rgb(232,99,91);
    border-radius: 1.2rem;
    color:  rgb(232,99,91);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: normal;
    margin-top: 2rem;
    
}

.btn_edit_profile:hover{
    background:  rgb(232,99,91);
    color:  rgb(255,255,255);
   

}

.objectives_container{
    border: 1px solid rgb(221, 222, 224);
    padding: 1rem;
}

.points_header i {
    color: rgb(255, 105, 180);
}

.objectives{
    margin: 0;
    padding: 0;
}

.objective_item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.objective_status i{
    color: rgb(50, 51, 53);
}

.btn_start_objective{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    border-radius: 1.2rem;
    color:   rgb(50, 51, 53);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.7rem;
    cursor: pointer;
    text-decoration: none;
}

.rookie_image{
    width: 100%;
    height: auto;
    max-width: 20em;

}


/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){

    .main_wrapper{
        display: flex;
        flex-direction: column;
        row-gap: 1em;
        padding: 1rem;
        margin-left: 2rem;
       
    }
    .profile_container{
        grid-column-start: 1;
        border-radius: 1.5rem;
        align-self: center
    }

    .objective_item{
        flex-direction: column;
    }

    .btn_start_objective{
        float: right;
        margin-top: 0.5rem;
    }

    .objective_item_title{
        font-size: 0.9rem;
    }

    .objective_item_title i{
        font-size: 1rem;
    }
    
  
  }



  
  /**
* For Tablets
*/
@media (min-width: 60em) and  (max-width: 75em){

    .main_wrapper{
        display: flex;
        flex-direction: column;
        column-gap: 1.5rem;
        row-gap: 1em;
        padding: 2.5rem;
    }

    .profile_container{
        grid-column-start: 1;
        border-radius: 1.5rem;
        align-self: center
    }

    
    
  
}