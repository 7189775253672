.tab_wrapper{
    display: grid;
    grid-template-columns: 0.4fr 2fr;
    column-gap: 0.5rem;
    width: 100%;
}

.active_tab{
    background-color: rgba(239, 90, 114, 0.22);
}


.tab_header{
    border-bottom: 1px solid #0000000f;
    box-shadow: 0 1.5px 1px 0 #00000040;
    height: max-content;
    background-color: rgb(255,255,255);
    border-radius: 0.5rem;
 
} 

.icon{
    display: none;
}


.tab_heading{
    border-bottom: 1px solid #0000000f;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    cursor: pointer;
} 

.tab_heading:hover{
    background-color: rgba(239, 90, 114, 0.22);
}

.tab_heading h5{
    margin: 0;

}

.tab_content{
    padding: 2.5rem;
    background-color: rgb(255,255,255);
    border-radius: 0.5rem;
}



/**
For Mobile Devices
*/
@media only screen and (max-width: 50em){
    .tab_wrapper{
        display: flex;
        flex-direction: column;
        column-gap: 0.5rem;
    }

    .tab_header{
        width: auto;
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        justify-content: space-between;
    }

    .icon{
        display: block;
    }
    
    .heading_title{
        display: none;
    }

    .tab_content{
        padding: 1.5rem;
        background-color: rgb(255,255,255);
        border-radius: 0.5rem;
    }

    .row_2{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
    
}




