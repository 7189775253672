@import url('https://fonts.googleapis.com/css?family=Nunito');


body {
  margin: 0;
  font-family:'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 1rem;
  overflow: hidden;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  padding: 0;
  margin: 0;
  min-height: 100%;
  color: rgb(47,48,51);
  
}

#root{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  background: #0000000d;
  height: 100vh;
  overflow-y: scroll; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  scrollbar-width: thin;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

input:focus, input.form-control:focus {

  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.text_align_center{
  text-align: center;
}

.pt_1{
  padding-top: 1rem;
}

.pt_2{
  padding-top: 2rem;
}


.pt_3{
  padding-top: 3rem;
}

.pt_4{
  padding-top: 4rem;
}

.pt_5{
  padding-top: 5rem;
}




/**
* For Tablets
*/
@media (min-width: 60em) and  (max-width: 75em){

  .card{
      width: 100%;
      max-width: 20em;
      padding: 2rem;
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {} 


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}