.text_area{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.text_area textarea{
    padding: 0.625em 1em;
    width: -moz-available;
    border: 1px solid rgba(128, 128, 128,0.25);
    color: rgb(128, 128, 128);
    border-radius: 0.25rem;
}